  import { useState, useEffect } from "react";
  import { Navbar, Nav, Container } from "react-bootstrap";
  import logo from '../assets/img/logo6.svg';
  import navIcon1 from '../assets/img/nav-icon1.svg';
  import navIcon2 from '../assets/img/test2.svg';
  import navIcon3 from '../assets/img/nav-icon3.svg';
  import { HashLink } from 'react-router-hash-link';
  import {
    BrowserRouter as Router
  } from "react-router-dom";

  export const NavBar = () => {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
      const onScroll = () => {
        if (window.scrollY > 50) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      }

      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
      setActiveLink(value);
    }

    return (
      <Router>
        <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="Logo" width="auto" height="auto"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
              </Nav>
              <span className="navbar-text">
                <div className="social-icon">
                  <a href="https://www.linkedin.com/in/jheel-thanki-616579283/" target="_blank"><img src={navIcon1} alt="" /></a>
                  <a href="https://github.com/jheel2006" target="_blank"><img src={navIcon2} alt="" /></a>
                  <a href="https://www.instagram.com/jheelthanki01/" target="_blank"><img src={navIcon3} alt="" /></a>
                </div>
                <HashLink to='#connect'>
                  <button className="vvd"><span>Contact Me</span></button>
                </HashLink>
              </span>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Router>
    )
  }
